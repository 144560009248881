import { Layout, Menu } from 'antd'
import { useState } from 'react'

import './styles.css'

import SideNav from '../SideNav'
import AddContent from '../AddContent'
import ManageArtists from '../ManageArtists'
import ManageMediaHouses from '../ManageMediaHouses'
import ManangeMovies from '../ManageMovies'
import ManageSeries from '../ManageSeries'
import ManageEpisodes from '../ManageEpisodes'
import ManageSongs from '../ManageSongs'
import UploadContent from "../UploadContent"
import Reports from '../Reports'

const { Header, Content } = Layout


const AdminModule = ({ userEmail }) => {
  const content = {
    "add-content": <AddContent />,
    "upload-content": <UploadContent />,
    "reports": <Reports />,
    "manage-artists": <ManageArtists />,
    "manage-media-houses": <ManageMediaHouses />,
    "manage-movies": <ManangeMovies />,
    "manage-series": <ManageSeries />,
    "manage-episodes": <ManageEpisodes />,
    "manage-songs": <ManageSongs />,
  }
  const [selectedTab, setSelectedTab] = useState("manage-movies")

  const logout = async () => {
    await sessionStorage.removeItem('access_token')
    window.location.reload()
  }

  return (
    <>
      <Layout>
        <Header>
          <div className="header-content">
            <img
              src="/images/logo.svg"
              alt="aao-logo"
              className="header-logo"
            />
            <Menu theme="dark" mode="horizontal">
              <Menu.Item key="1" onClick={logout} className="logout-btn">
                Logout
              </Menu.Item>
            </Menu>
          </div>
        </Header>
        <Layout>
          <SideNav updateTab={(tab) => setSelectedTab(tab)} userEmail={userEmail} />
          <Content>
            {content[selectedTab] ||
              'You do not have permissions to view this module'}
          </Content>
        </Layout>
      </Layout>
    </>
  );
}

export default AdminModule
