import { AudioTwoTone, ContactsTwoTone, ShoppingTwoTone, VideoCameraTwoTone } from '@ant-design/icons'
import { Row, Col, Card, Table, Tabs, Select, DatePicker } from 'antd'
import { useEffect, useContext, useState } from 'react'
import moment from "moment";
import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday"
import localeData from "dayjs/plugin/localeData"


import { Context } from '../../context'
import { Fetch } from '../../fetch'
import { convertIdToKey } from '../../utils'


const { Column } = Table;
const dateFormat = "YYYY-MM-DD";
// const date = '2022-11-29'
const { TabPane } = Tabs;

dayjs.extend(weekday);
dayjs.extend(localeData);

const Reports = ({ navTo }) => {
    const { token, apiUrl, superAdmin } = useContext(Context);
    const [filters, setFilters] = useState({
        date: moment().subtract(2, "days").format('YYYY-MM-DD'),
        // date: moment(date).format(dateFormat),
        content: null,
    });
    const [contentReport, setContentReport] = useState([]);
    const titles = []
    const data = []

    useEffect(() => {
        Fetch(`${apiUrl}/movies`, token, (result) => {
            const mov = convertIdToKey(result.movies);
            mov.forEach((m) => {
                titles.push(m.title)
            })
        })
        Fetch(`${apiUrl}/series`, token, (result) => {
            const s = convertIdToKey(result.series);
            s.forEach((series_single) => {
                titles.push(series_single.title)
            })
        })
    }, [apiUrl, token])

    useEffect(() => {
        Fetch(`${apiUrl}/reports/content_report?query_date=${filters.date}`, token, (result) => {
            const report = result.data
            report.forEach((r) => {
                if (titles.includes(r.title)) {
                    data.push(r)
                }
            });
            setContentReport(data)
        })
    }, [apiUrl, token, filters])

    return (
        <>
            <div className="manage-movies-wrapper">

                <Row>
                    <Col span={5}>
                        <DatePicker
                            style={{ width: "100%" }}
                            onChange={(value, dateString) =>
                                setFilters({
                                    ...filters,
                                    date: dateString ? dateString : "",
                                })
                            }
                            format={dateFormat}
                            defaultValue={dayjs().subtract(2, 'days')}
                        />
                    </Col>
                </Row>

                <Table
                    dataSource={contentReport}
                    pagination={false}
                >
                    <Column
                        title="Content Title"
                        dataIndex="title"
                        key="title"
                        align="center"
                    />
                    <Column
                        title="Duration"
                        dataIndex="duration"
                        key="duration"
                        align="center"
                    />
                    <Column
                        title="No of UU"
                        dataIndex="uniqueUsers"
                        key="uniqueUsers"
                        align="center"
                    />
                    <Column
                        title="APD in mins"
                        dataIndex="avgWatched"
                        key="avgWatched"
                        align="center"
                    />
                    <Column
                        title="Progress (%)"
                        dataIndex="avgProg"
                        key="avgProg"
                        align="center"
                    />
                </Table>
            </div>
        </>
    )
}

export default Reports
