import { Layout, Menu, Button, Divider } from 'antd'

import {
    DatabaseOutlined,
    KeyOutlined,
    VideoCameraAddOutlined,
    VideoCameraOutlined,
    DesktopOutlined,
} from '@ant-design/icons'

import './styles.css'
import { useState } from 'react'

const { Sider } = Layout
const { SubMenu } = Menu

const SideNav = ({ updateTab, userEmail }) => {
    const [selectedKeys, setSelectedKeys] = useState([])

    const manageContentEntries = [
        { key: "manage-movies", text: "Manage Movies" },
        { key: "manage-series", text: "Manage Series" },
        { key: "manage-episodes", text: "Manage Episodes" },
        { key: "manage-songs", text: "Manage Songs" },
    ]

    const masterDataEntries = [
        { key: "manage-artists", text: "Manage Artists" },
        { key: "manage-media-houses", text: "Manage Media Houses" },
    ]
    const reports = [
        { key: "reports", text: "Content Reports" },
    ]

    return (
        <Sider width={255} theme="light">
            <Button icon={<VideoCameraAddOutlined />} className="add-content-btn" onClick={() => { updateTab("add-content"); setSelectedKeys([]) }}>
                Add Content
            </Button>
            <Button
                icon={<VideoCameraAddOutlined />}
                className="add-content-btn"
                onClick={() => {
                    updateTab("upload-content");
                    setSelectedKeys([]);
                }}
            >
                Upload Content
            </Button>
            <Menu
                mode="inline"
                defaultSelectedKeys={["manage-movies"]}
                selectedKeys={selectedKeys}
                onSelect={(info) => setSelectedKeys(info.selectedKeys)}
                style={{ borderRight: 0 }}
            >
                {/* <Menu.Item
                    key="dashboard"
                    icon={<DesktopOutlined />}
                    onClick={() => updateTab("dashboard")}
                >
                    Dashboard
                </Menu.Item> */}
                <SubMenu key="content" title="Manage Content" icon={<VideoCameraOutlined />}>
                    {manageContentEntries.map(entry => (
                        <Menu.Item key={entry.key} onClick={() => updateTab(entry.key)}>
                            {entry.text}
                        </Menu.Item>
                    ))}
                </SubMenu>
                <SubMenu key="data" title="Master Data" icon={<DatabaseOutlined />}>
                    {masterDataEntries.map(entry => (
                        <Menu.Item key={entry.key} onClick={() => updateTab(entry.key)}>
                            {entry.text}
                        </Menu.Item>
                    ))}
                </SubMenu>
                {/* <SubMenu key="reports" title="Reports" icon={<DatabaseOutlined />}>
                    {reports.map(entry => (
                        <Menu.Item key={entry.key} onClick={() => updateTab(entry.key)}>
                            {entry.text}
                        </Menu.Item>
                    ))}
                </SubMenu> */}
                <Menu.Item key="user"
                    style={{
                        position: 'absolute',
                        bottom: "3%",
                        zIndex: 1,
                        color: '#fff',
                        backgroundColor: '#666',
                        margin: 0,
                    }}
                    onClick={() => { }}
                >
                    {userEmail}
                </Menu.Item>
                <Menu.Item key="user"
                    style={{
                        position: 'absolute',
                        bottom: 0,
                        zIndex: 1,
                        color: '#fff',
                        backgroundColor: '#666',
                        margin: 0
                    }}
                    onClick={() => { }}
                >
                    {"Copyright by AAONXT 2023"}
                </Menu.Item>
            </Menu>
        </Sider>
    )
}

export default SideNav
