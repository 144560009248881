import { useContext, useState } from "react";
import * as tus from "tus-js-client";
import { Context } from "../../context";
import { Post } from "../../fetch";
import { Progress, Space, Tag, Card, message } from "antd";

const UploadVideo = (props) => {
  const { token, apiUrl, transcodeUrl, superAdmin } = useContext(Context);
  const [trailerUploading, setTrailerUploading] = useState(false);
  const [trailerUploadProgress, setTrailerUploadProgress] = useState(0);
  const [trailerExists, setTrailerExists] = useState(false);

  const handleChange = (e, type) => {
    const file = e.target.files[0];
    let setUploadProgressFn;
    setTrailerUploading(true);
    setUploadProgressFn = setTrailerUploadProgress;

    const upload = new tus.Upload(file, {
      endpoint: `${transcodeUrl}/files`,
      retryDelays: [0, 3000, 5000, 10000, 20000],
      metadata: {
        filename: file.name,
        filetype: file.type,
      },
      onError: (error) => {
        console.log("Failed because: " + error);
      },
      onProgress: (bytesUploaded, bytesTotal) => {
        var percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2);
        setUploadProgressFn(percentage);
        console.log(bytesUploaded, bytesTotal, percentage + "%");
      },
      onSuccess: () => {
        Post(
          `${transcodeUrl}/submitJob`,
          token,
          {
            id: props.upload._id,
            loc: upload.url.split("/").slice(-1)[0],
            media_type: `${props.upload.type}`,
          },
          (result) => {
            console.log(result);
          },
          () => {
            console.error(`Error transcoding content`);
          }
        );
        setUploadProgressFn(0);
        message.success("Your Content is succesfully uploaded");
        console.log("Download %s from %s", upload.file.name, upload.url);
      },
    });

    upload.start();
  };

  return (
    <Card
      title={"Main Content (Mp4 only)"}
      size="default"
      style={{ width: 600 }}
    >
      {"playbackUrl" in props.activeObj ? (
        <>
          <img
            style={{ maxWidth: 360, maxHeight: 120 }}
            src={
              "trailerImage" in props.activeObj
                ? props.activeObj.trailerImage + '/public'
                : "detailImage" in props.activeObj
                  ? props.activeObj.detailImage + '/public'
                  : props.activeObj.cardImage + '/public'
            }
          ></img>
          <br />
          <br />
        </>
      ) : null}

      <Space direction="horizontal">
        {"playbackUrl" in props.activeObj ? (
          <Tag color="green" style={{ fontSize: 16 }}>
            Content Exists
          </Tag>
        ) : null}
        <input
          type="file"
          onChange={(e) => handleChange(e, "trailer")}
          accept="video/mp4"
        />
      </Space>
      {trailerUploadProgress ? (
        <Progress percent={trailerUploadProgress} />
      ) : null}
    </Card>
  );
};

export default UploadVideo;
